.quoteCardsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Raleway', sans-serif;
    margin: 48px auto;
}

.quoteCardsDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .quoteCardsDiv {
        flex-direction: column;
    }
}