.infoCard {
    background-color: #e0e3eb;
    max-width: 270px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin:40px;
    font-family: 'Raleway', sans-serif;
}

.infoCardIcon {
    width: 80px;
    height: auto;
    color: #022378;
    margin: 30px;
}

.infoCardHeader {
    color: #022378;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    margin: 0;
    padding: 0 50px
}

.infoCardCopy {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 20px 40px;
    margin: 0;
}

@media only screen and (max-width: 760px) {
    .infoCard {
        margin: 20px;
        padding: 20px;
        width: 100%;
    }
}