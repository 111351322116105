a.button {
    width: 220px;
    height: 48px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    border: none;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
    padding: 0 24px;
    font-family: 'Raleway', sans-serif;
    max-width: 90%;
};

@media only screen and (max-width: 1100px) {
    a.button {
        font-size: 12px;
    }
}