ol {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: center;
    list-style: none;
    padding: 0;
}

@media only screen and (max-width: 1000px) {
    .infoCardDiv {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}