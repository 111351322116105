.pageHeadSection {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.pageHeadImage {
    background-image: url('../../../images/pageHeadImage.webp');
    min-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.pageHeadHead {
    display: flex;
    justify-content: space-between;
}

.pageHeadLogo {
    max-width: 157px;
    margin: 64px;
    margin-bottom: 24px;
}

.pageHeadLogoText {
    max-width: 157px;
    padding-top: 0;
}

.pageHeadTextDiv {
    color: white;
    font-family: 'Raleway', sans-serif;
    margin: 150px 64px 250px 64px;
    max-width: 380px;
}

.pageHeadTitle {
    font-family: 'Fanwood Text', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height:  51px;
}

.pageHeadCopy {
    font-size: 16px;
    line-height: 27px;
}

.pageHeadHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .pageHeadHead {
        flex-direction: column;
    }
    .pageHeadTextDiv {
        margin: 150px 32px 250px 32px;
    }
    .pageHeadLogo {
        max-width: 100px;
        margin: 32px;
    }
}