.serviceExplainationSection {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
	margin: 64px 0;
}

.serviceExplainationTextDiv {
	display: flex;
	flex-direction: column;
	width: 40%;
}

.serviceExplainationHeader {
	font-weight: 400;
	font-family: 'Fanwood Text', serif;
	font-style: normal;
	font-size: 48px;
	line-height: 63px;
	color: #1e1e1e;
	margin: 16px 0;
}

.serviceExplainationCopy {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 27px;
	color: #333333;
	font-family: 'Raleway', sans-serif;
}

.serviceExplainationImageDiv {
	width: 40%;
	height: 800px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.serviceExplainationImage {
	max-width: 416px;
	width: auto;
	margin: auto;
	border-radius: 11px;
	height: 576px;
	object-fit: cover;
}

@media only screen and (max-width: 1000px) {
	.serviceExplainationImageDiv {
		display: none;
	}
	.serviceExplainationTextDiv {
		width: 90%;
		padding: 32px !important;
		max-width: 760px;
	}
}
