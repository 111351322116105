.areasOfPracticeSection {
	font-family: 'Raleway', sans-serif;
}

.areasOfPracticeImg {
	max-width: 100%;
	background-image: url('../../../images/areasOfPractice.image.webp');
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
}

.overlayDiv {
	background: rgba(13, 29, 70, 0.75);
	width: 100%;
	height: 100%;
	opacity: 0.75;
}

.practiceAreasTextDiv {
	padding: 100px 100px;
	color: white;
	width: 560px;
}

.phoneLink {
	color: white;
}

.line {
	border-top: solid 1px white;
	width: 100px;
	height: 2px;
	margin: 64px 20px 0 20px;
}

.practiceAreasTitle {
	font-family: 'Fanwood Text', serif;
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 52px;
}

.practiceAreasSubtitle {
	text-transform: uppercase;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 1px;
	margin-top: 8px;
}

.practiceAreasP1,
.practiceAreasP2,
.practiceAreasP3 {
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 171.6%;
	margin: 20px 0;
}

.practiceAreasP3 {
	margin-bottom: 100px;
}

@media only screen and (max-width: 1000px) {
	.practiceAreasTextDiv {
		width: 80vw;
		overflow: visible;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 48px;
	}
}
