.titleDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.line {
    border-top: solid 1px #333333;
    width: 100px;
    height: 2px;
    margin-top: 58px;
}

.subtitle {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 8px;
}

.title {
    font-family: 'Fanwood Text', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
}

@media only screen and (max-width: 760px) {
    .titleDiv {
        margin: 0 32px;
    }
}