.quoteCard {
    background-color: #E0E3EB;
    color: #022378;
    font-family: 'Raleway', sans-serif;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 580px;
    min-width: 40vw;
    border-radius: 11px;
    margin: 14px;
}

.quoteTextDiv {
margin: 20px 40px 20px 0;
}

@media only screen and (max-width: 768px) {
    .quoteCard {
        width: 90vw;
        min-width: 90vw;
        max-width: 90vw;
    }
}