.aboutBlurb {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: #ececec;

}

.aboutTextDiv {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    max-width:  760px;
    margin: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.aboutPhotoDiv {
    display: flex;
    flex-direction: column;

}

.aboutImg {
    max-width: 451px;
    margin: auto;
    width: 100%;
}

.aboutNameDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 32px;
    margin-bottom: 0;
}

.aboutName {
    font-family: 'Fanwood Text', serif;
    font-size: 40px;
    line-height: 52px;
    font-weight: 400px;
    color: #022378;
    font-style: normal;
    margin: 5px;
    padding: 0;

}

@media only screen and (max-width: 1330px) {
    .aboutBlurb {
        flex-direction: column-reverse;
    }
    .aboutPhotoDiv {
        margin-top: 64px;
    }
}